<template>
  <transition name='basic' v-if="!$route.meta.alive">
    <div class='transition' :key='firstbranch'>
      <span class='insurance' v-html='$route.path' />
      <slot />
    </div>
  </transition>
  <transition name='basic' v-else>
    <div class='transition' :key='firstbranch'>
      <keep-alive v-if='$route.meta.keepAlive'>
        <span class='insurance' v-html='$route.path' />
        <slot />
      </keep-alive>
    </div>
  </transition>
</template>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  props: ['transition'],
  computed: {
    firstbranch(){
      if(this.$route.path.split('/')[2] == 'projects') return this.$route.path;
      return this.$route.path.split('/')[2]
    }
  }
}
</script>

<style scoped>
div.transition {
  z-index: 0;
  position: relative;
  width: 100%;
  will-change: opacity;
  transform: translate3d(0,0,0);
  --time: 0.25s;
}

div.transition.basic-enter-active {
  transition: all var(--time)var(--bezier) var(--time);
  position: absolute;
  top: 0;
  opacity: 0;
}
div.transition.basic-enter-to {
  opacity: 1;
}
div.transition.basic-leave-active {
  transition: opacity var(--time) var(--bezier) 1ms;
  position: absolute;
  top: 0;
}
div.transition.basic-leave-to {
  opacity: 0;
}
div.transition.basic-leave {
  opacity: 1;
}

div:empty {
  display: none;
}
span.insurance {
  top: 0;
  opacity: 0;
  position: fixed;
}
</style>
