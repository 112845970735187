<template>
  <main id='News' v-if='news.template == "news"'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p02.png' />-->
    <router-view name='aside' />
    <veil :show='$route.matched.some(({ name }) => (name == "News Item"))' />
    <h1 v-if='news.strings' v-html='news.strings.title' />
    <header v-if='news.strings'>
      <nav v-if='news.strings.categories'>
        <button @click='selectedCategory="All"' v-html='yaml(kirby.site.ui).all' />
        <button once @click='selectedCategory=category' v-html='category' v-for='(category, index) in news.strings.categories.split(", ")' :key='index' />
      </nav>
      <nav>
        <!--<button>Archive</button>-->
      </nav>
    </header>
    <nav id='News'>
      <router-link :to='newsitem.uri' v-for='newsitem in news.children' v-show='categoryIsSelected(newsitem.strings.category)'>
        <div>
          <h5 class='date' v-html='newsitem.strings.date' />
          <imageBox v-for='(gallery, index) in stringtojson(newsitem.strings.gallery)' :key='index' :images='gallery.content.images' :page='newsitem'/>
        </div>
        <summary>
          <h5 v-html='newsitem.strings.category' />
          <h3 v-html='newsitem.strings.title' />
          <small v-html='newsitem.strings.excerpt' />
        </summary>
      </router-link>
    </nav>
    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function() {
    return {
      selectedCategory: 'All',
    }
  },
  methods: {
    categoryIsSelected: function(categoriesstring) {
      let categories = categoriesstring.split(', ');
      categories.push('All')
      return categories.includes(this.selectedCategory);
    },
  },
  computed: {
    news: function() {
      if (this.kirby.page.template == 'news') return this.kirby.page;
      return this.kirby.pages.news;
    }
  }
}
</script>

<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin-top: 8rem;
    margin-bottom: 4.5rem;
  }

  h2 {
    max-width: 40rem;
  }

  h5 {
    font-size: var(--regular);
    text-align: left;
  }

  header nav * {
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 0.015em;
  }

  button:not(:last-of-type)::after {
    content: '/';
    margin-left: 1em;
    margin-right: 1em;
  }

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  nav#News a {
    display: flex;
    flex-direction: var(--columndirection);
    justify-content: space-between;
    user-select: none;
    margin-bottom: 3rem;
  }

  nav#News a div {
    border-top: var(--thinline);
    flex-grow: 1;
    max-width: var(--leftcolumnwidth);
  }

  nav#News a summary {
    flex-grow: 1;
    max-width: var(--rightcolumnwidth);
    text-align: left;
    padding-bottom: 3rem;
  }

  @media screen and (min-width: 800px) {
    nav#News a summary {
      border-top: var(--thinline);
    }
  }

  nav#News a summary small {
    letter-spacing: 0.03em;
    display: block;
    font-size: var(--regular);
    line-height: 1.6em;
    max-width: 48em;
  }
</style>
