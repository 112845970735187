<template>
  <figure class='gallery'>
    <header v-if='filenames.length > 1'>
      <nav>
        <i @click='current = index-1' :class='{active: current==index-1}' v-for='index in filenames.length' :key='index'>{{index}}</i>
      </nav>
    </header>
    <section @click='nextOrPrev'>
      <nav class='back-and-forth' v-if='filenames.length > 1'>
        <arrow class='arrow left' direction='right' />
        <arrow class='arrow right' direction='left' />
      </nav>
      <div v-for='(image, index) in filenames' :class='getClass(index)' :key='index'>
        <imageBoxWithFiles :file="files[image]" :sizes='sizes' />
      </div>
    </section>
  </figure>
</template>

<script>
import mixins from '.././mixins.js'
export default {
  mixins: [mixins],
  props: ['files', 'filenames', 'sizes'],
  data: function() {
    return {
      current: 0
    }
  },
  methods: {
    getClass: function(index){
      if(index == this.current) return 'current';
      if(index == (this.current + 1 < this.filenames.length ? this.current + 1 : 0)) return 'next';
      if(index == (this.current - 1 >= 0 ? this.current - 1 : this.filenames.length-1)) return 'prev';
    },
    next: function(){
      this.current = this.current + 1 < this.filenames.length ? this.current + 1 : 0;
    },
    nextOrPrev: function(event){
      let distance = (event.clientX-(window.innerWidth/2));
      if (distance >= 0) this.current = this.current + 1 < this.filenames.length ? this.current + 1 : 0;
      if (distance < 0) this.current = (this.current - 1 >= 0 ? this.current - 1 : this.filenames.length-1);
    },
    getCaption(current){
      var file = this.files[this.filenames[current]];
      return file.meta.caption;
    }
  }
}
</script>

<style scoped>

figure.gallery {
  box-sizing: border-box;
  position: relative;
  margin: auto;
  margin-top: 2rem;
}

section {
  width: 100%;
  padding-bottom: 66%;
  position: relative;
}

section div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

section div.current {
  opacity: 1;
}

section div:not(.current) {
  opacity: 0;
}

nav.back-and-forth {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  height: 4rem;
  width: 100%;
}

header nav {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

header nav i {
  font-size: 0.6rem;
  font-style: normal;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  width: 2.25em;
  padding: 0.45em 0.5em 0.5em;
  width: 2.5em;
  border-radius: 50%;
  border: var(--thinline);
  margin-right: 0.5em;
  height: 2.5em;
  user-select: none;
}

header nav i.active, header nav i:hover {
  border-color: white;
}

svg.arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

svg.arrow:hover {
  cursor: pointer;
}

nav.back-and-forth svg.left {
  left: 1.5em;
}


nav.back-and-forth svg.right {
  right: 1.5em;
}



</style>

<style>
  figure.gallery figure.imagebox img.content.show {
    transform: scale(0.9);
  }
</style>
