<template>
  <main id='Projects' v-if='kirby.page.template == "index"'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p06.png' />-->
    <big v-html='kirby.page.content.description.kirbytext' />
    <header :class='{"mobile" : (size=="phone") }'>
      <nav>
        <button @click='selected="All"' v-html='yaml(kirby.site.ui).all' />
        <button @click='selected=category' :class='{active : (selected==category)}' v-html='pluralize(category)' v-for='(category, index) in kirby.page.strings.categories.split(", ")' :key='index' />
      </nav>
      <nav>
        <button @click='selected=type' :class='{active : (selected==type)}' v-html='type' v-for='(type, index) in kirby.page.strings.type.split(", ")' :key='index' />
      </nav>
    </header>
    <nav class='projects'>
      <projectModule v-for='(project, slug) in kirby.page.children'
                     :key='slug'
                     :project='project'
                     v-show='(project.visible && categoryIsSelected(project.strings.category)) || (project.visible && typeIsSelected(project.strings.type)) ' />
    </nav>
    <mainFooter />
  </main>
</template>

<script>
import mixins from '.././mixins.js';

export default {
  mixins: [mixins], //kirby(), urltopath(), etc
  data: function() {
    return {
      selected: 'All'
    }
  },
  methods: {
    categoryIsSelected: function(categoriesstring) {
      let categories = categoriesstring.split(', ');
      categories.push('All')
      return categories.includes(this.selected);
    },
    typeIsSelected: function(typestring) {
      let types = typestring.split(', ');
      types.push('All')
      return types.includes(this.selected);
    },
    pluralize: function(string) {
      if(string == 'Feature Film') return 'Feature Films';
      if(string == 'Documentary') return 'Documentaries';
      if(string == 'Series') return 'Series';
      if(string == 'Elokuva') return 'Elokuvat';
      if(string == 'Sarja') return 'Sarjat';
      if(string == 'Dokumentti') return 'Dokumentit';
      return string;
    }
  },
}
</script>

<style scoped>
  main {
    max-width: var(--main-max-width);
    padding: var(--main-padding);
    margin-left: auto;
    margin-right: auto;
    color: var(--rootcolor);
  }

  big {
    margin-top: 12rem;
    margin-bottom: 8rem;
  }

  header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5rem;
    flex-direction: var(--columndirection);
    line-height: 1.5rem;
  }

  nav.projects {
    margin-left: -1rem;
    width: calc(100% + 2rem);
    display: flex;
    justify-content: space-between;
    flex-direction: var(--columndirection);
    flex-wrap: wrap;
  }

  nav.projects a {
    width: var(--projectitemwidth);
    margin-bottom: 9rem;
    margin-left: var(--projectitemsidemargin);
    margin-right: var(--projectitemsidemargin);
    overflow: hidden;
    box-sizing: border-box;
  }

  nav.projects {
    margin-bottom: 8rem;
  }

  nav.projects::after {
    content: "";
    flex: auto;
  }

  header:not(.mobile) button {
    cursor: pointer;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.015em;
    padding: 0;
    position: relative;
    padding-bottom: 0.25em;
    background-position: bottom;
    text-decoration: none !important;
    background-repeat: repeat no-repeat;
    background-image: url('data:image/svg+xml;charset=utf8,\
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="1" viewBox="0 0 8 1">\
        <rect x="0" y="0" width="8" height="1" fill="transparent"/>\
      </svg>\
    ');
  }

  header:not(.mobile) button:not(:last-of-type) {
    margin-right: 3em;
  }

  header:not(.mobile) button.active {
    background-image: url('data:image/svg+xml;charset=utf8,\
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="1" viewBox="0 0 8 1">\
        <rect x="0" y="0" width="8" height="1" fill="%23dcdcdc"/>\
      </svg>\
    ');
  }

  header:not(.mobile) button:not(:last-of-type)::after {
    right: -2em;
    position: absolute;
    content: '/';
  }

  header:not(.mobile) button:hover {
    border-bottom: var(--thinline);
  }

  header.mobile {
    text-align: left;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  header.mobile button {
      font-size: 1.2rem;
      border: var(--thinline);
      border-radius: 0.75em;
      padding: 0.15em 1em 0.2em;
      margin: 0.25em;
  }

  header.mobile button.active {
      background-color: var(--white);
      color: var(--black);
  }
</style>
