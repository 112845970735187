<template>
  <main id='Detail' v-if='kirby.page.strings'>
    <!--<drawing src='assets/drawings/aurorastudios.com-v1-p08.png' />-->
    <h5 v-html='kirby.page.strings.category' />
    <h2 v-html='kirby.page.strings.title' />
    <blocks v-if='kirby.page.strings.videos' :content='JSON.parse(kirby.page.strings.videos)' :page='kirby.page' />
    <figure class='fallback' v-else-if='yaml(kirby.page.strings.cover).image'>
      <img :src='kirby.site.scheme + "://" + kirby.site.host + "/" + kirby.page.uri + "/" + yaml(kirby.page.strings.cover).image[0]'>
    </figure>
    <section class='details'>
      <aside>
        <ul>
          <li>
            <h6 v-html='kirby.page.strings.productionstatus' />
            <a v-if='!kirby.page.strings.toggle' v-html='kirby.page.strings.productionyear' />
            <a v-if='kirby.page.strings.toggle' v-html='kirby.page.strings.productiondate' />
          </li>
          <li v-for='(credit, index) in yaml(kirby.page.strings.maincredits)' :key='index'>
            <h6 v-html='credit.title' />
            <a class='button' v-if='credit.link' :href='credit.link' v-html='credit.participant' target='_blank'/>
            <a v-else v-html='credit.participant' />
          </li>
        </ul>
        <ul>
          <li v-for='(credit, index) in yaml(kirby.page.strings.additionalcredits)' :key='index'>
            <h6 v-html='credit.title' />
            <a class='button' v-if='credit.link' :href='credit.link' v-html='credit.participant' />
            <a v-else v-html='credit.participant' />
          </li>
        </ul>
      </aside>

      <article v-html='kirby.page.strings.description' />

      <nav class='buttons' v-if='kirby.page.strings.buttons'>
        <h4 v-html='"Related Links"'/>
        <a v-for='button in yaml(kirby.page.strings.buttons)' :href='button.link' v-html='button.content' target='_blank' />
      </nav>
    </section>

    <blocks v-if='kirby.page.strings.gallery' :content='JSON.parse(kirby.page.strings.gallery)' :page='kirby.page' />

    <relatedpages v-if='yaml(kirby.page.strings.relatedprojects)' :paths='yaml(kirby.page.strings.relatedprojects)' :title='yaml(kirby.site.ui).related' />

    <mainFooter />
  </main>
</template>

<style scoped>
  main#Detail {
    position: relative;
    margin: auto;
    padding: var(--main-padding);
    max-width: var(--main-max-width);
  }

  h5 {
    margin: 0;
  }

  h2 {
    margin: 0.25em auto;
    text-align: center;
  }

  section.details {
    display: flex;
    justify-content: space-between;
    padding-left: var(--detailspadding);
    flex-wrap: wrap;
  }

  aside {
    width: var(--detailswidth);
  }

  ul {
    font-size: var(--regular);
    list-style: none;
    text-align: left;
    columns: var(--columns);
    column-gap: 2rem;
    margin: 0;
    margin-bottom: 1rem;
    margin-right: 2rem;
    padding: 0;
  }

  ul li {
    margin-bottom: 1.2em;
    display: inline-block;
    width: 100%;
  }

  ul h6 {
    text-transform: uppercase;
    margin: 0;
    font-size: inherit;
    color: var(--gray);
    padding-right: 2em;
    box-sizing: border-box;
  }

  article {
    width: var(--detailswidth);
    font-size: var(--medium);
    line-height: 1.4em;
    text-align: left;
    box-sizing: border-box;
    padding-right: var(--detailbodypadding);
  }

  nav.buttons {
    text-align: left;
    width: 100%;
    margin-bottom: 2rem;
  }

  nav.buttons h4 {
    margin-bottom: 2.5rem;
    font-size: var(--regular);
  }

  nav.buttons a {
    border: var(--thinline);
    padding: 0.4em 1.3em 0.5em;
    border-radius: 2em;
    margin-right: 0.75em;
    font-size: var(--regular);
  }

  figure.fallback {
    padding: 0;
    margin: 0;
    width: 100%;
    overflow: hidden;
  }

  figure.fallback img {
    padding: 0;
    margin: 3rem 0 8rem;
    width: 100%;
    overflow: hidden;
  }
</style>

<script>
import mixins from '.././mixins.js';
export default {
  mixins: [mixins], //kirby(), urltopath(), etc
}
</script>
